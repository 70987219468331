import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

const enabled = document.head.hasAttribute('data-logrocket-enabled')

if (enabled) {
  LogRocket.init('6feidz/wellsheet', {
    serverURL: '/logrocket-ingest/i',
    network: {
      responseSanitizer: response => {
        if (response.status === 0) {
          response.status = 299
        }

        return response
      },
    },
  })
  setupLogRocketReact(LogRocket)
}
